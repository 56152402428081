<template>
    <div v-if="show" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30">
        <div class="bg-white p-4 rounded-lg shadow-lg w-72">
            <h3 class="text-lg font-semibold mb-2">Quick Popup</h3>

            <!-- Input list -->
            <label class="block mb-2">
                <span class="text-sm text-gray-600">Option</span>
                <select v-model="selectedOption" class="w-full px-2 py-1 border rounded mt-1">
                    <option disabled value="">Please select an option</option>
                    <option v-for="(option, index) in options" :key="index" :value="option">{{ option }}</option>
                </select>
            </label>

            <!-- Buttons -->
            <div class="flex justify-end space-x-2 mt-4">
                <button @click="cancel" class="px-4 py-2 text-gray-500 bg-gray-200 rounded">Cancel</button>
                <button @click="confirm" class="px-4 py-2 text-white bg-blue-500 rounded">Confirm</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: Boolean, // Boolean to control popup visibility
        options: Array, // List of options for the input list
    },
    data() {
        return {
            selectedOption: "", // For the selected value in the list
        };
    },
    methods: {
        confirm() {
            this.$emit("confirm", this.selectedOption); // Emit selected option
        },
        cancel() {
            this.$emit("cancel"); // Emit cancel action
        },
    },
};
</script>
