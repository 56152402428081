<template>
    <div class="relative inline-block text-left">
        <!-- Label for Dropdown -->

        <!-- Button -->
        <div>
            <button
                :style="{ width: width, height: height }"
                :class="['w-full block mt-2 px-4 py-3 rounded-lg ', 'text-sm leading-[15.74px] font-manrope text-[#8D98AA]', 'border-[#E0E4EC] bg-[#F7F9FC] p-2 rounded-md']"
                @click="toggleDropdown"
                class="inline-flex w-full justify-between items-center gap-x-1.5"
                type="button"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
            >
                {{ selectedItem || firstItem }}
                <svg class="h-5 w-5 text-[#8D98AA] transition-transform duration-200" :class="{ 'rotate-180': showMenu }" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                        fill-rule="evenodd"
                        d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
                        clip-rule="evenodd"
                    />
                </svg>
            </button>
        </div>

        <!-- Dropdown Menu -->
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-if="showMenu"
                class="absolute z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                :class="['text-[14px] leading-[15.74px] font-manrope text-[#8D98AA]', 'border-[#E0E4EC] p-2 rounded-md']"
                :style="{ width: width }"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
            >
                <ul class="py-1" role="none">
                    <li
                        v-for="(item, index) in items"
                        :key="index"
                        @click="selectItem(item)"
                        class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                        role="menuitem"
                        tabindex="-1"
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        firstItem: {
            type: String,
            default: "Select an option",
        },
        items: {
            type: Array,
            default: () => ["Option 1", "Option 2", "Option 3"],
        },
        width: {
            type: String,
            default: "200px",
        },
        height: {
            type: String,
            default: "auto",
        },
    },
    data() {
        return {
            showMenu: false,
            selectedItem: null,
        };
    },
    methods: {
        toggleDropdown() {
            this.showMenu = !this.showMenu;
        },
        selectItem(item) {
            this.selectedItem = item;
            this.showMenu = false;
        },
    },
};
</script>

<style scoped>
/* Custom styles if needed */
</style>
